import { Injectable } from '@angular/core';
import { Http } from '@capacitor-community/http';
import { TietovarastoService } from '../tietovarasto.service';
import { v4 as uuidv4 } from 'uuid';
import { asetustiedosto } from '../config/config-cli';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private localNm: string;
  private localAvain = '';
  private localOikeusOk: boolean;
  private localdebug = false;
  private apiUrl: string = asetustiedosto.ymparisto.apiUrl;

  constructor(
    private tietovarasto: TietovarastoService,
  ) {
      this.localOikeusOk = false;
      this.lueMuistista();
      this.tarkistaTiedot(this.localAvain, this.localNm);
  }

  get oikeusOk() {
    return this.localOikeusOk;
  }

  lokaa(rivi: any, rivi2?: any, rivi3?: any, rivi4?: any) {
    if (this.localdebug) {
      console.log('auth.service: ' , rivi, rivi2, rivi3, rivi4);
    };
  };

  async tarkistaTiedot(key: string, nimi: string): Promise<boolean> {
    if (key?.length === 30) {
      this.localOikeusOk = await this.tarkistaKayttooikeusApilta(key);
      if ( this.localOikeusOk === true ) {
        this.tietovarasto.set('apiKey', key);
        let id = await this.tietovarasto.get('id');
        if ( !id ) {
          id = this.uusiKayttaja();
          this.tietovarasto.set('id', id);
        }
        if ( nimi.length < 1 ) { nimi = id; }
        this.tietovarasto.set('nm', nimi);
        return true;
      } else {
        this.lokaa('Aloitussivu: virheellinen api-avain: ', key, ' localOikeusOk: ', this.localOikeusOk);
        return false;
      };
    }
    // jos ei api avainta -> alert?
    else {
      this.lokaa('Api-avain puuttuu');
      return false;
    }
  }

  async lueMuistista() {
    const avain = await this.tietovarasto.get('apiKey');
    const nm = await this.tietovarasto.get('nm');
    // jos avainta ei löydy
    if (!avain || !(avain.length === 30) ) {
      this.localAvain = '';
    }
    else {
      this.localAvain = avain;
    }
    if ( nm?.length > 0 ) {
      this.tietovarasto.set('nm', nm);
    } else {
      this.localNm = this.tietovarasto.get('nm');
    }
  }

  async tarkistaKayttooikeusApilta(key: string): Promise<boolean> {
    this.lokaa('aloitussivu: haetaan käyttöoikeustieto APIlta');

    const options = {
      url: `${this.apiUrl}/kirjautuminen/tarkasta`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data: `{ "apiKey": "${key}" }`
    };
    const response: any = await Http.post(options);
    this.lokaa('aloitussivu: response.data: ', response.data);
    this.lokaa(`aloitussivu: response.data.oikeusOk: ${response.data.oikeusOk}`);
    this.lokaa('aloitussivu: Tarkastettu apilta');
    if (response.data.oikeusOk === 'true') {
      return true;
    } else {
      return false;
    }
  };

  async tunnistautuminen() {
    let id = await this.tietovarasto.get('id');
    if (id.length === 0) {
      id = this.uusiKayttaja();
      //const nm = id;
      let nm = await this.tietovarasto.get('nm');
      this.lokaa(`Nikki: ${nm}`);
      if (nm < 1) {
        nm = id;
        this.tietovarasto.set('nm',nm);
      }
      this.tietovarasto.set('id',id);
      this.lokaa(`Käyttäjä luotu: ${id} / ${nm}`);
    }
    else {
      const nm = await this.tietovarasto.get('nm');
      //let nm = 'TestiTs';
      this.lokaa(`Olemassa oleva käyttäjä tunnistettu: ${id} / ${nm}`);
      //this.apiPaivitaKayttaja(id, nm);
    }
  }

  uusiKayttaja(): string {
    return uuidv4();
  }

}
