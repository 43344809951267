import { Injectable } from '@angular/core';
// import { v4 as uuidv4 } from 'uuid';
import { Geolocation, Geoposition } from '@awesome-cordova-plugins/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Http } from '@capacitor-community/http';
import { TietovarastoService } from './tietovarasto.service';
import { asetustiedosto } from './config/config-cli';

@Injectable({
  providedIn: 'root'
})
export class MapapiService {
  private localKayttajat: Array<Kayttaja>;
  private localKayttajatTxt: string;
  private localLokaatio: Geoposition;
  private localdebug = false;
  private localMarkkeritApilta: Array<Kayttaja>;
  private localValittuSijainti: any;
  private apiUrl: string = asetustiedosto.ymparisto.apiUrl;

  constructor(
    private geolocation: Geolocation,
    public platform: Platform,
    private androidPermissions: AndroidPermissions,
    private tietovarasto: TietovarastoService,
    ) {
    this.lokaa('constructor - Startti');
    this.platform.ready().then( () => {
      this.lokaa('constructor() - platform ready');
      this.lokaa(this.platform.platforms());
    });
    this.initialisoi();
    this.lokaa('constructor - Loppu');
  }

  get kayttajat() {
    return this.localKayttajat;
  }

  get lokaatio() {
    return this.localLokaatio;
  }

  get tamaKayttaja() {
    return this.haeTamaKayttaja();
  }

  get valittuSijainti() {
    return this.localValittuSijainti;
  }

  set valittuSijainti(sijainti: any) {
    this.lokaa(`Tallennetaan sijaintia valittu: ${sijainti}`);
    this.localValittuSijainti = sijainti;
  }

  lokaa(rivi: any, rivi2?: any, rivi3?: any) {
    if (this.localdebug) {
      console.log('mapapi.service: ' , rivi, rivi2, rivi3);
    };
  };

  async initialisoi() {
    this.lokaa('mapapi-Provider - initialisoi() - Startti');
    if (this.platform.is('android' || 'ios' || 'cordova' || 'mobile')) {
      this.lokaa(`Androidissa: ${this.platform.url()}`);
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => this.lokaa('Has permission?',result.hasPermission),
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
      );
      this.androidPermissions.requestPermissions([
        this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
      ]);
    }
    else { this.lokaa('Muu kuin android');
    };
    this.lokaa('mapapi-Provider - Init Startti');
    await this.haeSijainti(await this.tietovarasto.get('id'), await this.tietovarasto.get('nm'));
    this.paivita();
    this.ajastettuKayttajahaku();
    this.ajastettuPalvelinpaivitys();
    this.lokaa('mapapi-Provider - Init tehty');
    this.lokaa('mapapi-Provider - initialisoi() - Loppu');
  }

  async paivita(): Promise<void> {
    this.localKayttajatTxt='';
    this.lokaa('paivitetään palvelimelta');
    let kayttajat = [];

    const options = {
      url: `${this.apiUrl}/kayttajat`,
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      //params: { size: 'XL' },
      //mode: 'no-cors',
    };

    this.lokaa('paivita() - Lähetetään GET:');
    this.lokaa(options);

    const response: any = await Http.get(options);
    this.lokaa(response.data);

    kayttajat = response.data.map( (text: any ) => {
      return(
        {
          kayttaja: text.kayttaja_id,
          laite: text.laite_id,
          lat: text.nykyinen_sijainti.y,
          lot: text.nykyinen_sijainti.x,
          sijainti_aika: text.nykyinen_sijainti_aika,
          nimimerkki: text.nimimerkki
        }
      );
    });

    this.lokaa('kayttajat:');
    this.lokaa(kayttajat);
    this.localKayttajat = kayttajat;
    this.localMarkkeritApilta = kayttajat; // Tämä on tilapäinen markkerihaku
    //this.kasitteleMarkkerit(); // Tämä on tilapäinen markkerihaku
    this.localKayttajatTxt = JSON.stringify(kayttajat)
    .replace('{', '<br />{',)
    .replace('}]','}<br />]');
    this.lokaa(this.localKayttajatTxt);
  }

  ajastettuKayttajahaku() { setInterval(() => {
      this.paivita();
    }, 5000);
  }

  async ajastettuPalvelinpaivitys() { setInterval( async () => {
    const id = await this.tietovarasto.get('id');
    const nm = await this.tietovarasto.get('nm');
    this.apiPaivitaKayttaja(id, nm);
  }, 5000);
}

// async tunnistautuminen() {
//     let id = await this.tietovarasto.get('id');
//     if (id.length === 0) {
//       id = this.uusiKayttaja();
//       //const nm = id;
//       let nm = await this.tietovarasto.get('nm');
//       if (this.localdebug) { this.lokaa(`Nikki: ${nm}`); };
//       if (nm < 1) {
//         nm = id;
//         this.tietovarasto.set('nm',nm);
//       }
//       this.tietovarasto.set('id',id);
//       if (this.localdebug) { this.lokaa(`Käyttäjä luotu: ${id} / ${nm}`); };
//       this.haeSijainti(id, nm);
//     }
//     else {
//       const nm = await this.tietovarasto.get('nm');
//       //let nm = 'TestiTs';
//       if (this.localdebug) { this.lokaa(`Olemassa oleva käyttäjä tunnistettu: ${id} / ${nm}`); };
//       this.apiPaivitaKayttaja(id, nm);
//     }
//   }

//   uusiKayttaja(): string {
//     return uuidv4();
//   }

  async haeSijainti(id: string, nm: string) {
    try {
      this.lokaa('Paikannetaan / haeSijainti()');
      this.localLokaatio = await this.geolocation.getCurrentPosition( { timeout: 30000 } );
      this.lokaa(this.localLokaatio);
      this.lokaa(`
        POSTI: {
        "laite": "${id}",
        "x": "${this.localLokaatio.coords.longitude}",
        "y": "${this.localLokaatio.coords.latitude}",
        "nimimerkki": "${nm}" }`
      );
    }
    catch (error) {
      if (this.localdebug) { this.lokaa(`Errori: ${error.message}`); };

      this.localLokaatio.coords.latitude = 22;
      this.localLokaatio.coords.latitude = 33;

      this.lokaa(this.localLokaatio);
      this.lokaa(
        `POSTI: { "laite": "${id}",
        "x": "${this.localLokaatio.coords.longitude}",
        "y": "${this.localLokaatio.coords.latitude}",
        "nimimerkki": "${nm}" }`
      );
    }

    try {
      const options = {
        url: `${this.apiUrl}/kayttajat`,
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        data: `{  "laite": "${id}",
                  "x": "${this.localLokaatio.coords.longitude}",
                  "y": "${this.localLokaatio.coords.latitude}",
                  "nimimerkki": "${nm}"
                }`
        //params: { size: 'XL' },
      };

      this.lokaa('haeSijainti() - Lähetetään: POST');
      this.lokaa(options);

      const response: any = await Http.post(options);
      this.lokaa(response.data);

    } catch (error) {
      console.error('APInan kanssa ongelmia - haeSijainti metodisti');
      console.error(error.message);
    }
  };

  async apiPaivitaKayttaja(id: string, nm: string) {
    try {
      this.lokaa('Paikannetaan / apiPaivitaKayttaja()');
      this.localLokaatio = await this.geolocation.getCurrentPosition( { timeout: 30000 } );
      this.lokaa(this.localLokaatio);
      this.lokaa(`{
                    'laite': '${id}',
                    'x': '${this.localLokaatio.coords.longitude}',
                    'y': '${this.localLokaatio.coords.latitude}',
                    'nimimerkki': '${nm}'
                  }`);
    }
    catch (error) {
      this.lokaa(`Errori: ${error.message}`);

      this.localLokaatio.coords.latitude = 22;
      this.localLokaatio.coords.latitude = 33;

      this.lokaa(this.localLokaatio);
      this.lokaa(`{
                    'laite': '${id}',
                    'x': '${this.localLokaatio.coords.longitude}',
                    'y': '${this.localLokaatio.coords.latitude}',
                    'nimimerkki': '${nm}'
                  }`);
    }

    try {
      const options = {
        url: `${this.apiUrl}/kayttajat`,
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        //params: { size: 'XL' },
        data: `{
                  "laite": "${id}",
                  "x": "${this.localLokaatio.coords.longitude}",
                  "y": "${this.localLokaatio.coords.latitude}",
                  "nimimerkki": "${nm}"
                }`
      };
      this.lokaa('apiPaivitaKayttaja() - Lähetetään: PUT');
      this.lokaa(options);
      const response: any = await Http.put(options);
      this.lokaa(response.data);

    } catch (error) {
      console.error('APInan kanssa ongelmia - apiPaivitaKayttaja');
      console.error(error.message);
    }
  }

  tallennaAsetukset(x: string, y: string, z: string) {
    this.tietovarasto.set('viimeisinLatitude', x);
    this.tietovarasto.set('viimeisinLongidude',y);
    this.tietovarasto.set('viimeisinSuumi', z);
  };

  async haeAsetukset(muuttuja: string): Promise<string> {
    return await this.tietovarasto.get(muuttuja);;
  };

  async haeTamaKayttaja(): Promise<object> {
    const id = await this.tietovarasto.get('id');
    const nm = await this.tietovarasto.get('nm');
    this.lokaa(`
          haeTamaKayttaja: ${id} / ${nm} / 
          x: ${this.localLokaatio.coords.longitude} / 
          y: ${this.localLokaatio.coords.latitude}`);
    return {  laite_id: id,
              nimimerkki: nm,
              x: this.localLokaatio.coords.longitude,
              y: this.localLokaatio.coords.latitude
            };
  }

  async haeNykyinenSijainti(): Promise<Sijainti> {
    const pos: Geoposition = await this.geolocation.getCurrentPosition( { timeout: 5000 } );
    return { lat: pos.coords.latitude, lot: pos.coords.longitude };
  }

  markkeritApilta(): Array<Kayttaja>  {
    return this.localMarkkeritApilta;
  }
}

export interface Sijainti {
  lat: number;
  lot: number;
};

export interface Kayttaja {
  kayttaja: number;
  laite: string;
  lat: number;
  lot: number;
  sijainti_aika: Date;
  nimimerkki: string;
};
