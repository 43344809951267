import { Injectable } from '@angular/core';
import { Http } from '@capacitor-community/http';
import { asetustiedosto } from './config/config-cli';

@Injectable({
  providedIn: 'root'
})
export class ViestiService {

  private localdebug = false;
  private apiUrl: string = asetustiedosto.ymparisto.apiUrl;

  constructor() {
    this.lokaa('constructor - Startti');
    this.lokaa('constructor - Loppu');
  }

  lokaa(rivi: any, rivi2?: any, rivi3?: any) {
    if (this.localdebug) {
      console.log('viesti.service: ' , rivi, rivi2, rivi3);
    };
  };

  async haeTapahtumaViestit(tapahtuma: any): Promise<Array<TapahtumaViesti>> {
    this.lokaa('paivitetään palvelimelta');
    //const viestit: Array<TapahtumaViesti> = [];

    const options = {
      url: `${this.apiUrl}/viestit/tapahtumaviestit/` + tapahtuma,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    };
    this.lokaa('haeTapahtumanViestit() - Lähetetään GET:');
    const response: any = await Http.get(options);
    this.lokaa(response.data);

    // viestit = response.data.map( (text: TapahtumaViesti ) => {
    //   return(
    //     {
    //       tapahtumaId: text.tapahtumaId,
    //       lahettaja: text.lahettaja,
    //       aika: text.aika,
    //       viesti: text.viesti
    //     }
    //   );
    // });

    // viestit.filter( (v) => {
    //   this.lokaa('haeTapahtumanViestit - filter - v: ' + v + ' this.tapahtumaId: ' + this.tapahtumaId);
    //   this.lokaa('typeof(this.tapahtumaId) : ' + typeof(this.tapahtumaId) + '/ typeof(v.tapahtumaId) : ' + typeof(v.tapahtumaId));
    //   return (this.tapahtumaId === v.tapahtumaId );
    // });
    return response.data;
  };

  async lahetaViesti(obj: TapahtumaViesti | Viesti) {
    try {
      if (this.localdebug) { console.log('Lähetetään uutta viestiä'); };
      const options = {
        url: `${this.apiUrl}/viesti`,
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        data: obj
      };

      const response: any = await Http.post(options);
      console.log('lahetaViesti', response.data);

    } catch (error) {
      console.error('APInan kanssa ongelmia - lahetaViesti metodisti');
      console.error(error.message);
    }
  };
}

export interface TapahtumaViesti {
  tapahtumaId: string;
  lahettaja: string;
  aika?: string;
  viestityyppi?: number;
  viesti: string;
  nimimerkkiLahettaja?: string;
}

export interface Viesti {
  lahettaja: string;
  aika: string;
  viesti: string;
  nimimerkkiLahettaja?: string;
  vastaanottaja: string;
}
