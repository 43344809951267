import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: '',
    redirectTo: 'kartta/kartta',
    pathMatch: 'full'
  },
  {
    path: 'kartta',
    redirectTo: 'kartta/kartta',
    pathMatch: 'full'
  },
  {
    path: 'kartta/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kartta/kartta.module').then( m => m.KarttaPageModule)
  },
  {
    path: 'kayttaja',
    redirectTo: 'kayttaja/kayttaja',
    pathMatch: 'full',
    //canActivate: [AuthGuard],
  },
  {
    path: 'kayttaja/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kayttaja/kayttaja.module').then( m => m.KayttajaPageModule)
  },
  {
    path: 'viestit',
    //canActivate: [AuthGuard],
    redirectTo: 'viestit/viestit',
    pathMatch: 'full'
  },
  {
    path: 'viestit/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./viestit/viestit.module').then( m => m.ViestitPageModule)
  },
  {
    path: 'halytykset',
    //canActivate: [AuthGuard],
    redirectTo: 'halytykset/halytykset',
    pathMatch: 'full'
  },
  {
    path: 'halytykset/halytykset',
    canActivate: [AuthGuard],
    loadChildren: () => import('./halytykset/halytykset.module').then( m => m.HalytyksetPageModule)
  },
  {
    path: 'halytykset/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./halytykset/halytykset.module').then( m => m.HalytyksetPageModule)
  },
  {
    path: 'luo-tapahtuma',
    canActivate: [AuthGuard],
    loadChildren: () => import('./luo-tapahtuma/luo-tapahtuma.module').then( m => m.LuoTapahtumaPageModule)
  },
  {
    path: 'aloitussivu',
    loadChildren: () => import('./aloitussivu/aloitussivu.module').then( m => m.AloitussivuPageModule)
  },
  {
    path: 'tapahtumaviestit/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tapahtumaviestit/tapahtumaviestit.module').then( m => m.TapahtumaviestitPageModule)
  },
  {
    path: 'tapahtumaviestit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tapahtumaviestit/tapahtumaviestit.module').then( m => m.TapahtumaviestitPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
