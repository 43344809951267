import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MapapiService } from './mapapi.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { HalytysserviceService } from './halytysservice.service';
import { AuthService } from './auth/auth.service';
import { KarttaPage } from './kartta/kartta.page';
import { TietovarastoService } from './tietovarasto.service';
import { ViestiService } from './viesti.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MapapiService,
    HalytysserviceService,
    AuthService,
    TietovarastoService,
    ViestiService,
    Geolocation,
    Platform,
    AndroidPermissions,
    KarttaPage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
