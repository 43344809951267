import { Component, OnInit, Input } from '@angular/core';
import { MapapiService } from '../mapapi.service';
import { HalytysserviceService } from '../halytysservice.service';
//import { KarttaPage } from '../kartta/kartta.page';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-luo-tapahtuma',
  templateUrl: './luo-tapahtuma.page.html',
  styleUrls: ['./luo-tapahtuma.page.scss'],
})
export class LuoTapahtumaPage implements OnInit {
  @Input() lokaatio: any;
  public tapahtumatyypit: any = [];
  public tapahtumaSeuraa: boolean;
  public kaytaNykyistaSijaintia: boolean;
  public kaytettavaSijainti: KaytettavaSijainti;

  private localdebug = false;
  private tapahtumatyyppiRadio: any;
  private tapahtumaLisatieto: string;

  constructor(
      private api: MapapiService,
      private tapahtumat: HalytysserviceService,
//      private kartta: KarttaPage,
      private modalCtrl: ModalController,
    ) { }

  ngOnInit() {
    this.kaytaNykyistaSijaintia = true;
    this.tapahtumaSeuraa = false;
    this.tapahtumatyypit = this.tapahtumat.tapahtumatyypit;
    this.lokaa('Modaalille nykyinenLat: ' + this.lokaatio.nykyinenLat + ' nykyinenLot: ' + this.lokaatio.nykyinenLot);
    this.lokaa('Modaalille valittuLat: ' + this.lokaatio.valittuLat + ' valittuLot: ' + this.lokaatio.valittuLot);
    this.kaytettavaSijainti = { lat: this.lokaatio.nykyinenLat, lot: this.lokaatio.nykyinenLot };
  }

  lokaa(rivi: any, rivi2?: any, rivi3?: any) {
    if (this.localdebug) {
      console.log('luo-tapahtuma: ' , rivi, rivi2, rivi3);
    };
  };

  async luoTapahtuma() {
    this.lokaa('Luotapahtuma');
    const kayttajanTiedot: any = await this.api.tamaKayttaja;

    const obj = `{
      "laite_id": "${kayttajanTiedot.laite_id}",
      "tapahtumatyyppi_id": "${this.tapahtumatyyppiRadio}",
      "lisatieto": "${this.tapahtumaLisatieto}",
      "x": "${this.kaytettavaSijainti.lot}",
      "y": "${this.kaytettavaSijainti.lat}", 
      "sijainti_seuraa": "${ this.tapahtumaSeuraa === true ? 1 : 0 }",
      "aktiivinen": "1",
      "poistettu": "0"
    }`;
    await this.tapahtumat.luoTapahtuma(obj);
    this.lokaa('Tapahtuman pitäisi olla luotu');
    this.closeModal();
  };

  peruutaTapahtuma() {
    this.lokaa('Ei vielä toiminnassa');
    //this.kartta.suljeTapahtuma();
    this.closeModal();
  }

  muutaTapahtumatyyppivalinta(arvo: any) {
    this.lokaa('Valittu: ' + arvo.detail.value);
    this.tapahtumatyyppiRadio = arvo.detail.value;
  }

  muutaTapahtumaLisatieto(arvo: any) {
    this.lokaa('Lisätieto: ' + arvo.detail.value);
    this.tapahtumaLisatieto = arvo.detail.value;
  }

  muutaTapahtumaSeuraa(arvo: any) {
    this.lokaa('Seuraa: ' + arvo.detail.value);
    this.tapahtumaSeuraa = arvo.detail.value === 'true' ? true : false;
  }

  muutaSijaintiLat(arvo: any) {
    this.lokaa('muutaSijaintiLat' + arvo.detail.value);
    this.kaytettavaSijainti.lat = Number(arvo.detail.value);
  }

  muutaSijaintiLot(arvo: any) {
    this.lokaa('muutaSijaintiLot' + arvo.detail.value);
    this.kaytettavaSijainti.lot = Number(arvo.detail.value);
  }

  muutaKaytaNykyistaSijaintia(arvo: any) {
    this.kaytaNykyistaSijaintia = arvo.detail.value === 'true' ? true : false;
    this.lokaa('muutaKaytaNykyistaSijaintia' + ' / ' + arvo.detail.value + ' / ' + typeof(arvo.detail.value));
    this.lokaa('kaytaNykyistaSijaintia: ' + this.kaytaNykyistaSijaintia);
    if ( this.kaytaNykyistaSijaintia === true ) {
      this.lokaa('this.kaytaNykyistaSijaintia === true');
      this.kaytettavaSijainti = { lat: this.lokaatio.nykyinenLat, lot: this.lokaatio.nykyinenLot };
      this.lokaa('lokaatio nykyinen: ' + this.lokaatio.nykyinenLat + '/' + this.lokaatio.nykyinenLot );
      this.lokaa('lokaatio valittu: ' + this.lokaatio.valittuLat + '/' + this.lokaatio.valittuLot );
      this.lokaa('sijainti nyt: ' + this.kaytettavaSijainti.lat + '/' + this.kaytettavaSijainti.lot);
    } else {
      this.lokaa('this.kaytaNykyistaSijaintia === false');
      this.kaytettavaSijainti = { lat: this.lokaatio.valittuLat, lot: this.lokaatio.valittuLot };
      this.lokaa('lokaatio nykyinen: ' + this.lokaatio.nykyinenLat + '/' + this.lokaatio.nykyinenLot );
      this.lokaa('lokaatio valittu: ' + this.lokaatio.valittuLat + '/' + this.lokaatio.valittuLot );
      this.lokaa('sijainti nyt: ' + this.kaytettavaSijainti.lat + '/' + this.kaytettavaSijainti.lot);
    }
  }


  async closeModal() {
    await this.modalCtrl.dismiss();
  }
}

export interface KaytettavaSijainti {
  lat: number;
  lot: number;
}
