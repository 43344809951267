import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Leaflet from 'leaflet';
import { MapapiService } from '../mapapi.service';
import { HalytysserviceService } from '../halytysservice.service';
import { ModalController } from '@ionic/angular';
import { LuoTapahtumaPage } from '../luo-tapahtuma/luo-tapahtuma.page';
import { AuthService } from '../auth/auth.service';
//import { Geoposition } from '@awesome-cordova-plugins/geolocation';
import { ActivatedRoute } from '@angular/router';
import { formatDate, getLocaleId } from '@angular/common';

@Component({
  selector: 'app-kartta',
  templateUrl: './kartta.page.html',
  styleUrls: ['./kartta.page.scss'],
})
export class KarttaPage implements OnInit, OnDestroy {

  public folder = 'LähiHelppi';
  public seurantaPaalla: boolean;
  map: Leaflet.Map;
  //private gjl: Leaflet.GeoJSON;
  private sovelluksenNimi = this.folder;
  private localdebug = false;
  private valittuKohta: Leaflet.Marker;
  private valittuKohtaKartalla = false;
  private markkeri: Leaflet.Marker;
  private omaSijainti: Leaflet.Marker;
  private naytaMarkerinPopup: number;

  //00cf3f
  private markerVihrea = Leaflet.icon({
    iconUrl: 'assets/kartta/markerit/vihrea.jpg',
    iconSize:     [25, 25], // size of the icon
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });

  private markerPurppura = Leaflet.icon({
    iconUrl: 'assets/kartta/markerit/valittupiste.png',
    iconSize:     [25, 25], // size of the icon
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });

  //a40000
  private markerPunainen = Leaflet.icon({
    iconUrl: 'assets/kartta/markerit/punainen.png',
    iconSize:     [25, 25], // size of the icon
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });

  //0057c3
  private markerSininen = Leaflet.icon({
    iconUrl: 'assets/kartta/markerit/sininen.png',
    iconSize:     [25, 25], // size of the icon
    popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
  });

  private markerOmaSijainti = Leaflet.icon({
    iconUrl: 'assets/kartta/markerit/marker-icon-2x.png',
    iconAnchor:   [12.5, 41],
    iconSize:     [25, 41], // size of the icon
    popupAnchor:  [12.5, 41],
  });

  constructor(
    private api: MapapiService,
    private tapahtumat: HalytysserviceService,
    private luoTapahtuma: ModalController,
    private autentikointi: AuthService,
    private route: ActivatedRoute,
  ) {
    // setInterval(() => {
    //   //this.lokaa('');
    //   //this.lokaa('Intervalli...');
    // }, 5000);
  }

  ngOnDestroy(): void {
    //this.lokaa('Tuhotaan...');
    this.tallennaAsetukset();
  };

  //
  ngOnInit() {
    this.leafletMap();
    this.map.invalidateSize();
    this.saadaKartanKoko();
    this.haeAsetukset();
    this.paivitaMarkeritAjastus();
    this.omaSijaintiMarkeriPaivitys();
    //this.devausintervalliKarttaobjektille();
    this.route.queryParams.subscribe(params => {
      this.lokaa(this.route.snapshot.paramMap.get('id'));
      this.naytaMarkerinPopup = parseInt(this.route.snapshot.paramMap.get('id'), 10);
      this.lokaa(this.naytaMarkerinPopup);
      this.paivitaMarkerit();
    });
  }

  lokaa(rivi: any, rivi2?: any, rivi3?: any) {
    if (this.localdebug) {
      console.log('kartta.page: ' , rivi, rivi2, rivi3);
    };
  };

  // Vaaditaan että tiilet toimii kunnolla
  saadaKartanKoko = () => {
    setInterval(() => {this.map.invalidateSize();}, 50);
  };

  devausintervalliKarttaobjektille = () => {
    setInterval(() => {this.lokaa('devausintervalliKarttaobjektille(): karttaobjekti: ', this.map);}, 5000);
  };

  // Päivitetään APIlta haettu markkeritilanne kartalle
  paivitaMarkeritAjastus = () => {
    setInterval(() => this.paivitaMarkerit(), 5000);
  };

  async paivitaMarkerit() {
    this.lokaa('20220319 - paivitaMarkerit');
    await this.api.paivita();
    await this.tapahtumat.haeHalytykset();
    if (this.tapahtumat.tapahtumat().length > 0 || this.api.markkeritApilta().length > 0) {
      this.lokaa('20220319 - paivitaMarkerit - if');
      //otetaan kopiot apilta tulleista (spread syntax)
      const apiKayttajat = [...this.api.markkeritApilta()];
      const apiTapahtumat = [...this.tapahtumat.tapahtumat()];
      let poistetaanSeuraava = false;

      //layerit array.forEach(element => {
      this.map.eachLayer( (taso: any) => {
        // jos aputaso eli poistettu layer +1 poistetaan taso
        if (poistetaanSeuraava) { this.map.removeLayer(taso); poistetaanSeuraava = false; }
        // jos layerissä feature
        else if ('feature' in taso ) {
          //   luetaan layeristä merkin tyyppi (käyttäjä?, tapahtuma?)
          //   jos käyttäjä, mutta ei tapahtuma
          if ( taso.feature.properties.tyyppi === 'kayttaja' ) {
            //     jos api sisältää saman
            const obj: any = apiKayttajat.find( (apiKayttaja: any) => apiKayttaja.laite === taso.feature.properties.laite);
            if (obj) {
              //       tapahtumaarraystä haetaan tiedot ja päivitetään
              this.markkeriPaivitysKayttaja(obj, taso);
              //       poistetaan tapahtumaapikopioarraysta
              apiKayttajat.splice( obj, 1);
              //     muuten
            } else {
              // jos käyttäjä kartalla, mutta ei apilta tulleissa:
              //       poistetaan tämä layeri
              this.map.removeLayer(taso);
              //       apulayerin poisto _leaflet_id + 1:llä
              poistetaanSeuraava = true;
            }
            //     muuten
            //   jos tapahtuma, mutta ei käyttäjä
          } else if ( taso.feature.properties.tyyppi === 'tapahtuma' ) {
            //     jos api sisältää saman
            const obj: any = apiTapahtumat.find( (apiTapahtuma: any) =>
              apiTapahtuma.tapahtuma_id === taso.feature.properties.tapahtuma_id
            );
            if (obj) {
              //       tapahtumaarraystä haetaan tiedot ja päivitetään
              this.markkeriPaivitysTapahtuma(obj, taso);
              //       poistetaan tapahtumaapikopioarraysta
              apiTapahtumat.splice( obj, 1);
              //     muuten
            } else {
              //       apulayerin poisto _leaflet_id + 1:llä
              //TILAPÄINEN//this.map.removeLayer(this.map._layers[taso._leaflet_id + 1]);
              //       poistetaan tämä layeri
              this.map.removeLayer(taso);
              poistetaanSeuraava = true;
            }
          };
        } // taso
      }); //eachlayer
      // lisätään apikopioiden jäljellejääneet
      //   jos käyttäjä
      //     lisätään käyttäjämarkkeri FUNKTIO
      apiKayttajat.forEach( (uusiKayttaja) => this.markkeriLuoKayttaja(uusiKayttaja));
      //   jos tapajhtuma
      //     lisätään tapahtumamarkkeri FUNKTIO
      apiTapahtumat.forEach( (uusiTapahtuma) => this.markkeriLuoTapahtuma(uusiTapahtuma));
    };
    this.lokaa('Karttaobjekti: ', this.map);
  };

  markkeriLuoKayttaja(kayttaja: any): void {
    const geoJsonObjekti: any = {
      type: 'Feature',
      properties: {
          kayttaja: kayttaja.kayttaja,
          laite: kayttaja.laite,
          nimimerkki: kayttaja.nimimerkki,
          lat: kayttaja.lat,
          lot: kayttaja.lot,
          saika: kayttaja.sijainti_aika,
          tyyppi: 'kayttaja'
      },
        geometry: {
          type: 'Point',
          coordinates: [kayttaja.lat, kayttaja.lot],
      }
    };

    const geoJsonMarkkeri = Leaflet.geoJSON(geoJsonObjekti,
      {
        // style: (feature: any) => {
        //   return {
        //     color: 'black'
        //   };
        // },
        onEachFeature: (feature: any, layer: any) => {
            layer.bindPopup(`<div style="color: blue">
              Nimimerkki: <div style="color: black"><b>${feature.properties.nimimerkki}</b></div><br />
              Laite-ID:  <div style="color: black">${feature.properties.laite}</div><br />
              Käyttäjä-ID: <div style="color: black">${feature.properties.kayttaja}</div><br />
              Sijainti lat: <div style="color: black">${feature.properties.lat}</div>
              Sijainti lot: <div style="color: black">${feature.properties.lot}</div><br />
              Sijainnin ajankohta: <div style="color: black">${feature.properties.saika}
              </div><br /></div>
            `);
            layer.setLatLng([kayttaja.lat, kayttaja.lot]);
        },
        pointToLayer: (feature, latlng) => {
          return Leaflet.marker(latlng, { icon: this.markerSininen, draggable: false });
        }
      });
    //this.markkeritKartallaData[a].addTo(this.map);
    this.map.addLayer(geoJsonMarkkeri); //tämäkin toimii
  };

  markkeriLuoPopup(obj: any): string {
    const pvm = new Date(obj.ajankohta);
    this.lokaa('pvm: ', pvm, );
    const teksti = `
      <div style="color: blue">
      Tapahtuma: <span style="color: black"><b>${obj.tapahtuma_id}</b></span><br /><br />
      Sijainti lat: <span style="color: black">${obj.lat}</span><br />
      Sijainti lot: <span style="color: black">${obj.lot}</span><br /><br />
      Ajankohta: <div style="color: black">${pvm.toISOString().substring(0, 16).replace('T', ' ')}</div><br /></div>
      Lisätieto: <div style="color: black">${obj.lisatieto}</div><br /></div>
    `;
    return teksti;
  }

  markkeriLuoTapahtuma(tapahtuma: any): void {
    const geoJsonObjekti: any = {
      type: 'Feature',
      properties: {
          tapahtuma_id: tapahtuma.tapahtuma_id,
          laite_id: tapahtuma.laite_id,
          tapahtumatyyppi_id: tapahtuma.tapahtumatyyppi_id,
          nimimerkki: tapahtuma.nimimerkki,
          lat: tapahtuma.lat,
          lot: tapahtuma.lot,
          ajankohta: tapahtuma.ajankohta,
          lisatieto: tapahtuma.lisatieto,
          sijainti_seuraa: tapahtuma.sijainti_seuraa,
          aktiivinen: tapahtuma.aktiivinen,
          poistettu: tapahtuma.poistettu,
          tyyppi: 'tapahtuma'
      },
        geometry: {
          type: 'Point',
          coordinates: [tapahtuma.lat, tapahtuma.lot],
      }
    };
    let tamanTapahtumanId: any;
    const geoJsonMarkkeri = Leaflet.geoJSON(geoJsonObjekti,
      {
        // style: (feature: any) => {
        //   return {
        //     color: 'black'
        //   };
        // },
        onEachFeature: (feature: any, layer: any) => {
            tamanTapahtumanId = feature.properties.tapahtuma_id;
            layer.bindPopup(this.markkeriLuoPopup(feature.properties));
            layer.setLatLng([tapahtuma.lat, tapahtuma.lot]);
        },
        pointToLayer: (feature, latlng) => {
          return Leaflet.marker(latlng, { icon: this.markerPunainen, draggable: false });
        }
      });
    //this.markkeritKartallaData[a].addTo(this.map);
    // const avaaPopup = this.naytaMarkerinPopup === tamanTapahtumanId;
    // if (avaaPopup) { this.lokaa('tosiiii'); }
    // this.map.addLayer(geoJsonMarkkeri);
    // this.markkeri.addTo(this.map).openPopup();
    if ( this.naytaMarkerinPopup === tamanTapahtumanId ) {
      this.lokaa('popupopen');
      geoJsonMarkkeri.addTo(this.map).openPopup();
    } else {
      this.lokaa('popupeiopen');
      geoJsonMarkkeri.addTo(this.map);
    };
    //this.naytaMarkerinPopup === tamanTapahtumanId = this.map.openPopup?
      //  geoJsonMarkkeri.openPopup(): geoJsonMarkkeri
    //); //tämäkin toimii
  };

  markkeriPaivitysKayttaja(obj: any, taso: any): void {
    taso.setLatLng([obj.lat, obj.lot]);
    taso.setPopupContent(`<div style="color: blue">
      Nimimerkki: <div style="color: black"><b>${obj.nimimerkki}</b></div><br />
      Laite-ID:  <div style="color: black">${obj.laite}</div><br />
      Käyttäjä-ID: <div style="color: black">${obj.kayttaja}</div><br />
      Sijainti lat: <div style="color: black">${obj.lat}</div>
      Sijainti lot: <div style="color: black">${obj.lot}</div><br />
      Sijainnin ajankohta: <div style="color: black">${obj.sijainti_aika}</div><br /></div>
    `);
  };

  markkeriPaivitysTapahtuma(obj: any, taso: any): void {
    taso.setLatLng([obj.lat, obj.lot]);
    taso.setPopupContent(this.markkeriLuoPopup(obj));
    if ( this.naytaMarkerinPopup === obj.tapahtuma_id ) {
      this.lokaa('päivitätapahtuma popupopen');
      taso.openPopup();
      this.naytaMarkerinPopup = null;
    } else {
      this.lokaa('päivitätapahtuma popupeiopen');
    };

  };

  tokkaa(e: object) {
    this.lokaa(`tokkaa(e): Näppylä: ${e} painettu.`);
  }

  viestita(e: object) {
    this.lokaa(`viestita(e): Näppylä: ${e} painettu.`);
  }

  laheta(e: object) {
    this.lokaa(`laheta(e): Näppylä: ${e} painettu.`);
  }

  // Hakee edellisen sijainnin ja zoomaustason ja keskittää siihen
  async haeAsetukset() {
    try {
      const x = await this.api.haeAsetukset('viimeisinLatitude');
      const y = await this.api.haeAsetukset('viimeisinLongidude');
      const z = await this.api.haeAsetukset('viimeisinSuumi');
      this.map.setView([parseFloat(String(x)), parseFloat(String(y))], parseFloat(String(z)));
      //this.lokaa(`Haettiinasetuksia ${x} ${y} ${z}`);
    } catch (error) {
      this.lokaa('Ei ollu viimeisin muistissa: ', error);
    }
  }

  async tallennaAsetukset() {
    //this.lokaa('Tallennetaan asetuksia');
    const x = this.map.getCenter().lat.toString();
    const y = this.map.getCenter().lng.toString();
    const z = this.map.getZoom().toString();
    //this.lokaa(`Tallennettiinasetuksia ${x} ${y} ${z}`);
    this.api.tallennaAsetukset(x, y, z);
  }
  // ngAfterViewInit() {
  // }

  // Luo ja piirtää kartan kartta-sivulle
  leafletMap() {
    this.map = Leaflet.map('mapId').setView([65.759101918617375, 26.20001425793579], 5.5);
    //Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    this.map.doubleClickZoom.disable();
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
      attribution: `${this.sovelluksenNimi}`,
      minZoom: 1,
      maxZoom: 19,
      bounds: Leaflet.latLngBounds([-90,-180], [90, 180]),
    }).addTo(this.map);
    this.haeAsetukset().then( () => {
      this.lokaa(`luodaan Zoomend ja moveend-eventti`);
      this.map.on('zoomend', () => {
        this.lokaa(`Zoomend-eventti`);
        this.tallennaAsetukset();
      });
      this.map.on('moveend', () => {
        this.lokaa(`Moveend-eventti`);
        this.tallennaAsetukset();
      });

      this.map.on('dblclick', (e: any) => {
        this.lokaa('touch Lat, Lon : ' + e.latlng.lat + ', ' + e.latlng.lng);
        //const lll: Leaflet.LatLng = Leaflet.latLng(e.latlng.lat, e.latlng.lng);
        //this.valittuKohta.setLatLng([89,89]).bindPopup('Valittu kohta: ', e.latlng).openPopup();
        if (this.valittuKohtaKartalla === false) {
          this.markkeri = Leaflet
            .marker([e.latlng.lat, e.latlng.lng], { icon: this.markerPurppura })
            .bindPopup(`<b>Valittu sijainti:</b><br />
                        Lat: ${e.latlng.lat}<br />
                        Lot: ${e.latlng.lng}`
          );
          this.api.valittuSijainti = e.latlng;
          this.valittuKohtaKartalla = true;
          this.markkeri.addTo(this.map).openPopup();
          //this.map.addLayer(this.valittuKohta.openPopup());
        } else {
          // päivitys
          // this.markkeri
          //   .setLatLng([e.latlng.lat, e.latlng.lng])
          //   .bindPopup(`<b>Valittu sijainti:</b><br />
          //               Lat: ${e.latlng.lat}<br />
          //               Lot: ${e.latlng.lng}`
          // ).openPopup();
          // this.api.valittuSijainti = e.latlng;

          // poistatus
          this.map.removeLayer(this.markkeri);
          this.valittuKohtaKartalla = false;
        }
      });
      this.omaSijaintiMarkeri();
      this.paivitaMarkerit();
    });
  }

  async omaSijaintiMarkeri() {
    const sijainti: Sijainti = await this.api.haeNykyinenSijainti();
    this.lokaa('OMASIJAINTI: ', this.omaSijainti);
    if ( !(this.omaSijainti === undefined) ) {
      this.omaSijainti.setLatLng([sijainti.lat, sijainti.lot]);
      this.lokaa('OMASIJAINTI: true ');
    } else {
      this.omaSijainti = Leaflet
          .marker([sijainti.lat, sijainti.lot], {
            icon: this.markerOmaSijainti,
            title: 'Olet täsä',
            riseOnHover: true,
            zIndexOffset: 1000,
           })
          .addTo(this.map);
      this.lokaa('OMASIJAINTI: false ');
    }
  }

  omaSijaintiMarkeriPaivitys() {
    setInterval(() => this.omaSijaintiMarkeri(), 5000);
  }

  keskitaSijaintiin() {
    //this.lokaa('Keskitä sijaintiin...');
    this.map.setView([this.api.lokaatio.coords.latitude, this.api.lokaatio.coords.longitude], this.map.getZoom());
  };

  async seuraaSijaintia() {
    if (this.seurantaPaalla) {
      //this.lokaa('seuraaSijaintia pois päältä...');
      this.seurantaPaalla = false;
      this.map.locate({watch: false, setView: false, timeout: 30000});
    } else {
      //this.lokaa('seuraaSijaintia päälle...');
      this.seurantaPaalla = true;
      await this.autentikointi.tunnistautuminen();
      this.map.locate({watch: true, setView: true, timeout: 30000, maxZoom: this.map.getZoom()});
    }
  }

  async avaaLuoTapahtuma() {

    // interface ModalOptions {
    //   component: any;
    //   componentProps?: { [key: string]: any };
    //   presentingElement?: HTMLElement;
    //   showBackdrop?: boolean;
    //   backdropDismiss?: boolean;
    //   cssClass?: string | string[];
    //   animated?: boolean;
    //   swipeToClose?: boolean;

    //   mode?: 'ios' | 'md';
    //   keyboardClose?: boolean;
    //   id?: string;

    //   enterAnimation?: AnimationBuilder;
    //   leaveAnimation?: AnimationBuilder;
    // };

    const modaali = await this.luoTapahtuma.create({
      component: LuoTapahtumaPage,
      cssClass: 'small-modal',
      swipeToClose: true,
      componentProps: {
        lokaatio:{
          nykyinenLat: this.api.lokaatio.coords.latitude,
          nykyinenLot: this.api.lokaatio.coords.longitude,
          valittuLat: this.api.valittuSijainti ? this.api.valittuSijainti.lat : this.api.lokaatio.coords.latitude,
          valittuLot: this.api.valittuSijainti ? this.api.valittuSijainti.lng : this.api.lokaatio.coords.longitude,
        },
      }
    });
    await modaali.present();
  }

  public suljeTapahtuma() {
    this.luoTapahtuma.dismiss();
  }
}

export interface Sijainti {
  lat: number;
  lot: number;
}
