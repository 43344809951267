// Sisältää asiakassovelluksen muuttujat ja kehitysparametrit
export const asetustiedosto = {
	ymparisto: {
		apiUrl: 'https://helppi.dy.fi:3334'
	},
	asetukset: {
		debuggausCli: true,
	},
	lokalisaatio: {
	}
}