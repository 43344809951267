import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class TietovarastoService {
  private localStorage: Storage | null = null;

  constructor(
    private storage: Storage,
  ) {
    console.log('Tietovarasto - constructor - start');
    this.init();
    console.log('Tietovarasto - constructor - end');
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/.../);
    try {
      const varasto = await this.storage.create();
      this.localStorage = varasto;
    } catch (error) {
      console.log('Tietovarasto - init: ', error);
    }
  }

  public get(key: string): any {
    try {
      return this.localStorage.get(key);
    } catch (error) {
      console.log('Tietovarasto - get: ', error);
      console.log(error);
    }
  }
  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this.localStorage?.set(key, value);
  }

  public poista(key: string) {
    this.localStorage?.remove(key);
  }

}
