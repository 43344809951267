import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Kartta', url: '/kartta/kartta', icon: 'paper-plane' },
    { title: 'Hälytykset', url: '/halytykset/halytykset', icon: 'warning' },
    { title: 'Käyttäjä', url: '/kayttaja/kayttaja', icon: 'heart' },
    { title: 'Viestit', url: '/viestit/viestit', icon: 'mail' },
  ];
  public labels = ['Ensiaputaitoinen', 'Partiolainen', 'Metsuri'];
  constructor(public menuCtrl: MenuController) {}

  public naytaMenu() {
    this.menuCtrl.enable(true);
  }

  async naytaMenuToggle() {
    await this.menuCtrl.close();
    console.log('MenuToggle');
  }
}
