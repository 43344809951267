import { Injectable } from '@angular/core';
import { Http } from '@capacitor-community/http';
import { asetustiedosto } from './config/config-cli';

@Injectable({
  providedIn: 'root'
})
export class HalytysserviceService {
  private localdebug = false;
  private localHalytykset: Array<Tapahtuma>;
  private localTapahtumatyypit: Array<object> = [];
  private apiUrl: string = asetustiedosto.ymparisto.apiUrl;

  constructor() {
    this.lokaa('constructor - Startti');
    this.initialisoi();
    this.lokaa('constructor - Loppu');
  }

  get tapahtumatyypit() {
    return this.localTapahtumatyypit;
  };

  initialisoi = () => {
    this.ajastettuHalytyshaku();
    this.haeTapahtumatyypit();
  };

  lokaa(rivi: any, rivi2?: any, rivi3?: any) {
    if (this.localdebug) {
      console.log('halytysservice.service: ' , rivi, rivi2, rivi3);
    };
  };

  ajastettuHalytyshaku() { setInterval(() => {
      this.haeHalytykset();
    }, 5000);
  };

  async haeHalytykset(): Promise<void> {
      this.lokaa('paivitetään palvelimelta');
      let halytykset = [];

      const options = {
        url: `${this.apiUrl}/tapahtumat`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      };
      this.lokaa('paivita() - Lähetetään GET:');
      const response: any = await Http.get(options);
      this.lokaa(response.data);

      halytykset = response.data.map( (text: any ) => {
        return(
          {
            tapahtuma_id: text.tapahtuma_id,
            laite: text.laite_id,
            tapahtumatyyppi_id: text.tapahtumatyyppi_id,
            lisatieto: text.lisatieto,
            ajankohta: text.ajankohta,
            lot: text.sijainti.x,
            lat: text.sijainti.y,
            sijainti_seuraa: text.sijainti_seuraa,
            poistettu: text.poistettu,
            nimimerkki: text.nimimerkki,
            tapahtumanimi: text.tapahtumanimi
          }
        );
      });

      this.lokaa('halytykset:');
      this.lokaa(halytykset);
      this.localHalytykset = halytykset;
  };

  async luoTapahtuma(obj: any): Promise<number> {
    try {
      console.log('Lähetetään uutta tapahtumaa');
      const options = {
        url: `${this.apiUrl}/tapahtumat`,
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        data: obj
      };

      const response: any = await Http.post(options);
      console.log('LUOTAPAHTUMA', response.data);

    } catch (error) {
      console.error('APInan kanssa ongelmia - haeSijainti metodisti');
      console.error(error.message);
    }
    return 2;
  };

  async haeTapahtumatyypit(): Promise<Array<object>> {
    this.lokaa('haetaan tapahtumatyypit palvelimelta');

    const options = {
      url: `${this.apiUrl}/tapahtumat/tapahtumatyypit`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    };
    const response: any = await Http.get(options);
    this.lokaa(response.data);

    this.localTapahtumatyypit = response.data.map( (text: any ) => {
      return(
        {
          tapahtumatyyppi_id: text.tapahtumatyyppi_id,
          tapahtumatyyppi: text.tapahtumatyyppi
        }
      );
    });
    this.lokaa(`haetaan tapahtumatyypit:  ${this.localTapahtumatyypit}`);
    console.log(this.localTapahtumatyypit);
    return this.localTapahtumatyypit;
  };

  tapahtumatiedot(tapahtuma: number): Tapahtuma {
    this.lokaa('HaetaanTapahtumaTiedot');
    const palautettava: any = this.localHalytykset.find( ( t: Tapahtuma ) => {
        this.lokaa('tapahtumafind:');
        this.lokaa(t);
        return t.tapahtuma_id === tapahtuma;
      });
    this.lokaa(palautettava);
    return palautettava;
  }

  async poistaTapahtuma( tapahtuma: number, laite: string ) {
    const obj = { tapahtumaId: tapahtuma, laiteId: laite };
    try {
      if ( this.localdebug ) { console.log('Poistetaan tapahtumaa: ' + tapahtuma ); };
      const options = {
        url: `${this.apiUrl}/tapahtumat`,
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        data: obj
      };

      const response: any = await Http.del(options);
      console.log('POISTATAPAHTUMA', response.data);

    } catch (error) {
      console.error('APInan kanssa ongelmia - poistaTapahtuma metodisti');
      console.error(error.message);
    }
  };

  tapahtumat(): Array<Tapahtuma> {
    return this.localHalytykset;
  };
}

export interface Tapahtuma {
  tapahtuma_id: number;
  laite: string;
  tapahtumatyyppi_id: number;
  tapahtumanimi: string;
  lisatieto: string;
  ajankohta: Date;
  lot: number;
  lat: number;
  sijaintiSeuraa: boolean;
  poistettu: boolean;
  nimimerkki: string;
}
